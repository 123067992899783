import React from "react";
import { EXPLORER } from "../constants";
import { shortAddress } from "../utils/";

export default class TempHistory extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let tempHistory = this.props.tempHistory;
    return (
      <>
        <div className="gap-1"></div>
        <div className="card-text">
          {tempHistory.reverse().map((i, index) => (
            <div key={index} className="">
              <div className="d-flex justify-content-between mb-2">
                <span className="text-brand">
                  <a target="_blank" href={EXPLORER + "tx/" + i.hash}>
                    {" "}
                    {shortAddress(i.hash)}
                  </a>
                </span>
                <span className="d-flex align-items-center">
                  {i.type}{" "}
                  {i.status === "pending" && (
                    <div
                      className="ms-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden text-white">
                        Loading...
                      </span>
                    </div>
                  )}
                  {i.status === "success" && (
                    <svg
                      enableBackground="new 0 0 24 24"
                      height="1em"
                      fill="#ffffff"
                      viewBox="0 0 24 24"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ms-2"
                    >
                      <g>
                        <path d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z" />
                      </g>
                    </svg>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
