export const STAKING_POOL = [
  {
    pool: "USDLLGD",
    stakeToken: "USDL",
    getToken: "LGD",
    stakeTokenName: "USDL",
    getTokenName: "Legendex token",
  },
];

export const TOTAL_APPROVE = 1000000000;
export const CONFIRM_NUMBER = 1;
export const SOCIAL_LINK = {
  telegram: "https://t.me/AuroraChain_EN",
  telegramAnn: "https://t.me/AuroraChainANN",
  twitter: "https://twitter.com/AuroraChain_EN",
  medium: "#",
  github: "#",
};

export const LUCKY_REQUIRE = { rare: 0, epic: 100, legendary: 200 };

// MAINNET
export const NETWORK = 56;
export const TOKEN_INFO = {
  NFL: { address: "0x6d5c1ce968f46fd7c2288d63c24f1810a2295b0e", decimal: 6 },
  LP: { address: "0xfD5b8203F3a86d55B299076b3C78aCaf3d1f0F2B", decimal: 18 },
  LUCKY: { address: "0x918730C59537368cA9Dfcd71c9E8a62a21548495", decimal: 18 },
};
export const PAIR_ID = {
  //PAIR ID on UNISWAP => lower case
  ETH: "",
};
export const EXPLORER = "https://bscscan.com/";
export const RPC = "https://bsc-dataseed.binance.org";
export const INFURA =
  "https://mainet.infura.io/v3/b290a50e9362493e8882b002b9e44eb3";
export const FEE = 0.02;
export const TIMELOCK = 86400;
// TESTNET
// export const NETWORK = 97;
// export const TOKEN_INFO = {
//   NFL: { address: "0x0A159AE79C784601125712c8a489846aD5Fc2960", decimal: 6 },
//   LP: { address: "0x75be2f584f56c0096785e2e4ebc8f756ad82d58f", decimal: 18 },
//   LUCKY: { address: "0x7a7a5e7f863d61785a07937b089cd09070eb8138", decimal: 18 },
// };
// export const PAIR_ID = {
//   //PAIR ID on UNISWAP => lower case
//   ETH: "0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852",
// };
// export const EXPLORER = "https://testnet.bscscan.com/";
// export const RPC = "https://bsc-dataseed.binance.org";
// export const INFURA =
//   "https://mainet.infura.io/v3/b290a50e9362493e8882b002b9e44eb3";
// export const FEE = 0.02;
// export const TIMELOCK = 120;
