import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ConnectCard from "./views/ConnectCard";
import MyCardList from "./views/MyCardList";
import Card3D from "./views/Card3D";
import card from "../assets/cards/77.jpg";
import cardfront from "../assets/cards/cardfront.jpg";

import opensea from "../assets/images/opensea.svg";

import logo from "../assets/images/logo.svg";

class MyCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayShow: [],
    };
  }

  componentWillMount() {
    let arrayShow = [];
    for (let i = 1; i <= 105; i++) {
      let kind = i < 49 ? "c" : i < 83 ? "r" : i < 103 ? "sr" : "ssr";
      let limit = i < 49 ? 100 : i < 83 ? 50 : i < 103 ? 25 : 10;

      let iObj = {
        cardID: i,
        kind,
        limit,
      };
      arrayShow.push(iObj);
    }

    this.setState({ arrayShow });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    let wallet = this.props.wallet;
    return (
      <main className="main mb-5 ps-lg-3">
        <div className="container">
          <div className="row">
            <div className="col mt-3">
              <div class="gap-1" />
              <h1 className="text-3 fw-bold text-white">My CardFights</h1>
              <p class="title-description">Manage your cardfights</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-mb-5">
            {wallet.status === "connected" ? (
              <MyCardList wallet={this.props.wallet} />
            ) : (
              <div className="col">
                <div class="main-app">
                  <ConnectCard wallet={this.props.wallet} />
                </div>
              </div>
            )}
            <div className="gap-6" />
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(MyCards);
