import React from "react";
import {
  TOKEN_INFO,
  TOTAL_APPROVE,
  CONFIRM_NUMBER,
  EXPLORER,
} from "../constants";
import $ from "jquery";
import { shortAddress, loadAccountBalance, safeAmount } from "../utils/";
import arrow from "../../assets/images/svg/arrow-down-1.svg";
import lock from "../../assets/images/svg/lock.svg";
import { Logos } from "../../assets/CoinLogos";
import moment from "moment";
import numeral from "numeral";

export default class ClaimHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAur: true,
    };
  }

  render() {
    let wallet = this.props.wallet;
    // let history = this.props.hostory;

    return (
      <>
        {this.props.historyData.length !== 0 && (
          <div className="card mt-3">
            <div className="card-body text-center">
              <div className="card-title fs-5 fw-bold">Claim history</div>
              {this.props.historyData.length === 0 && (
                <div className="card-text py-4 fs-italic text-secondary">
                  Empty
                </div>
              )}
              {this.props.historyData.length > 0 &&
                this.props.historyData.reverse().map((i, index) => (
                  <div key={index} className="border-item">
                    <div className="d-flex justify-content-between">
                      <span className="text-brand">
                        <a href={EXPLORER + "tx/" + i.tx} target="_blank">
                          {shortAddress(i.tx)}
                        </a>
                      </span>
                      <span className="d-flex align-items-center monospace pointer">
                        {safeAmount(i.lucky, 18) + " LUCKY"}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between ">
                      <span className="small text-secondary">
                        {this.state.displayAur
                          ? moment(parseInt(i.timestamp) * 1000).fromNow()
                          : moment(parseInt(i.timestamp) * 1000).format("LLL")}
                      </span>
                      <span className="d-flex align-items-center monospace pointer">
                        {safeAmount(i.nfl, 6) + " NFL"}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    );
  }
}
