import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getContract } from "./utils/ContractLoader";
import History from "./views/History";
import WithdrawHistory from "./views/WithdrawHistory";
import ClaimHistory from "./views/ClaimHistory";

import { Logos } from "../assets/CoinLogos";
import {
  shortAddress,
  loadAccountBalance,
  safeAmount,
  convert,
} from "./utils/";

import chest0 from "../assets/images/chest0.png";
import chest1 from "../assets/images/chest1.png";
import chest2 from "../assets/images/chest2.png";
import chest3 from "../assets/images/chest3.png";
import TempHistory from "./views/TempHistory";
import ResultOpenChestModal from "./views/ResultOpenChestModal";

import $ from "jquery";
import numeral from "numeral";

import { Modal } from "bootstrap";

import {
  TOKEN_INFO,
  TOTAL_APPROVE,
  CONFIRM_NUMBER,
  EXPLORER,
  FEE,
} from "./constants";

// import OpenChestModal from "../views/OpenChestModal";

class FarmLP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "buy",
      contract: null, //* CONTRACT
      contractAddress: null, //* CONTRACT ADDRESS

      amount: null,
      historyArray: [],
      balance: 0,
      tempHistory: [],
      errMess: "",
      withdrawAmount: null,

      //* FARMED
      farmingAmount: null,
      withdrawHistoryArray: [],

      //* CLAIM
      luckyClaimable: 0,
      nflClaimable: 0,
      claimHistoryArray: [],
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleWithdrawAmountChange =
      this.handleWithdrawAmountChange.bind(this);
    this.withdrawLP = this.withdrawLP.bind(this);
  }

  async componentWillMount() {
    await this.setState(getContract("farmLP"));
    this.getUserInfo();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.wallet !== this.props.wallet) {
      if (nextProps.wallet.status === "connected") {
        this.getUserInfo();
      } else {
        this.setState({
          balance: 0,
          withdrawHistoryArray: [],
          claimHistoryArray: [],
          historyArray: [],
          tempHistory: [],
          farmingAmount: null,

          luckyClaimable: 0,
          nflClaimable: 0,
        });
      }
    }
  }

  async componentDidMount() {}

  handleAmountChange = (e) => {
    let amount = e.target.value;
    this.setState({
      amount,
    });
  };
  handleWithdrawAmountChange = (e) => {
    let withdrawAmount = e.target.value;
    this.setState({
      withdrawAmount,
    });
  };
  async getUserInfo() {
    var account = this.props.wallet.account;
    var contractAddress = this.state.contractAddress;
    var contract = this.state.contract;

    if (account) {
      //* GET TOKENS BALANCE
      let [balance] = await Promise.all([loadAccountBalance("LP", account)]);
      // console.log(balance);
      //* GET ALLOWED BALANCE
      const web3 = window.web3;
      var abi = require("human-standard-token-abi");
      let contractLP = new web3.eth.Contract(abi, TOKEN_INFO["LP"].address);

      let allowedAmount = await contractLP.methods
        .allowance(account, contractAddress)
        .call();
      allowedAmount = parseInt(allowedAmount) / 10 ** TOKEN_INFO["LP"].decimal;

      // let aurClaimable = await contract.methods.totalAur(account).call();
      // aurClaimable = safeAmount(aurClaimable, TOKEN_INFO["AUR"].decimal);

      //* GET HISTORY
      this.getHistory();

      //* CLAIMABLE
      let pendingToken = await contract.methods.pendingToken(0, account).call();
      // console.log(pendingToken);
      let luckyClaimable =
        pendingToken.rewardLucky / 10 ** TOKEN_INFO["LUCKY"].decimal;
      let nflClaimable =
        pendingToken.rewardNft / 10 ** TOKEN_INFO["NFL"].decimal;

      this.getFarmedInfo();
      this.getClaimHistory();

      this.setState({
        balance,
        allowedAmount,
        luckyClaimable,
        nflClaimable,
      });
    }
  }

  async getFarmedInfo() {
    var contract = this.state.contract;
    var account = this.props.wallet.account;
    //* LOAD FARMING AMOUNT, HISTORY FARMING DATA

    let result = await contract.methods.userInfo(0, account).call();

    // console.log(result);

    let farmingAmount =
      parseInt(result.amount) / 10 ** TOKEN_INFO["LP"].decimal;

    this.getWithdrawHistory();
    this.setState({ farmingAmount });
  }

  async getHistory() {
    var contract = this.state.contract;
    var account = this.props.wallet.account;

    let historyArray = await contract
      .getPastEvents("Deposit", {
        filter: { user: account, pid: 0 },
        fromBlock: 0,
        toBlock: "latest",
      })
      .then(function (events) {
        if (events.length > 0) {
          events = events.filter((i) => i.returnValues.user === account);

          return events.map(function (i) {
            // console.log(i);
            let elem = {
              tx: i.transactionHash,
              amount: i.returnValues.amount,
              // usdt: i.returnValues.usdtbuy,
              timestamp: i.returnValues.blockTime,
            };

            return elem;
          });
        } else return [];
      });

    this.setState({ historyArray });
  }

  async getClaimHistory() {
    var contract = this.state.contract;
    var account = this.props.wallet.account;

    let claimHistoryArray = await contract
      .getPastEvents("Claim", {
        filter: { pid: 0 },
        fromBlock: 0,
        toBlock: "latest",
      })
      .then(function (events) {
        if (events.length > 0) {
          events = events.filter((i) => i.returnValues.user === account);

          return events.map(function (i) {
            // console.log(i);
            let elem = {
              tx: i.transactionHash,
              lucky: i.returnValues.rewardLucky,
              nfl: i.returnValues.rewardNfl,
              timestamp: i.returnValues.blockTime,
            };
            // console.log(elem);
            return elem;
          });
        } else return [];
      });

    this.setState({ claimHistoryArray });
  }

  async getWithdrawHistory() {
    var contract = this.state.contract;
    var account = this.props.wallet.account;

    // let withdrawHistoryArray = await contract
    //   .getPastEvents("Withdraw", {
    //     filter: { user: account, pid: 0 },
    //     fromBlock: 0,
    //     toBlock: "latest",
    //   })
    //   .then(function (events) {
    //     if (events.length > 0) {
    //       events = events.filter((i) => i.returnValues.user === account);

    //       return events.map(function (i) {
    //         // console.log(i);
    //         let elem = {
    //           tx: i.transactionHash,
    //           amount: i.returnValues.amount,
    //           // usdt: i.returnValues.usdtbuy,
    //           timestamp: i.returnValues.blockTime,
    //         };
    //         // console.log(elem);

    //         return elem;
    //       });
    //     } else return [];
    //   });

    let result = await contract.methods.getWithdrawByAddress(0, account).call();
    // console.log(result);

    let withdrawHistoryArray = result.map(function (i) {
      let elem = {
        status: i.status,
        amount: i.amount,
        timestamp: i.timwWithdraw,
      };

      return elem;
    });

    // console.log(withdrawHistoryArray);

    this.setState({ withdrawHistoryArray });
  }

  withdrawLP(index) {
    // index = 2;
    // console.log("clicked:", index);
    const web3 = window.web3;

    this.setState({ broadcasting: true });
    this.state.contract.methods
      .withdrawLp(0, index)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Withdraw LP",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  async approve() {
    const web3 = window.web3;
    let abi = require("human-standard-token-abi");
    let stakeTokenContract = new web3.eth.Contract(
      abi,
      TOKEN_INFO["LP"].address
    );

    let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
    this.setState({ approveStatus: false });

    stakeTokenContract.methods
      .approve(this.state.contractAddress, totalApprove)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // console.log(hash);
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Approve LP token",
        });
        // console.log(this.state.tempHistory);
        this.setState({ approveStatus: "pending" });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          // console.log(receipt.transactionHash);
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          // console.log(this.state.tempHistory);

          this.setState({ approveStatus: "success" });
        }
      })
      .on("error", (error) => {
        this.setState({
          approveStatus: "error",
          errMess: error.message,
        });
      });
  }

  farm() {
    const web3 = window.web3;

    let amountDecimal = convert(
      this.state.amount * 10 ** TOKEN_INFO["LP"].decimal
    ).toString();
    // console.log("amountDecimal", amountDecimal);

    this.setState({ broadcasting: true });
    this.state.contract.methods
      .deposit(0, amountDecimal)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Farm " + this.state.amount + " LP",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  claim() {
    const web3 = window.web3;

    this.setState({ broadcasting: true });
    this.state.contract.methods
      .claim(0)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Claim rewards",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  requestWithdraw() {
    const web3 = window.web3;

    let amountDecimal = convert(
      this.state.withdrawAmount * 10 ** TOKEN_INFO["LP"].decimal
    ).toString();
    // console.log("amountDecimal", amountDecimal);

    this.setState({ broadcasting: true });
    this.state.contract.methods
      .withdraw(0, amountDecimal)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Withdraw " + this.state.withdrawAmount + " LP",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  clearMess() {
    this.setState({ errMess: "" });
  }

  render() {
    let wallet = this.props.wallet;
    let status = !this.props.wallet.account
      ? "connect"
      : this.state.broadcasting
      ? "broadcasting"
      : this.state.approveStatus === "pending"
      ? "approving"
      : !this.state.amount
      ? "enter"
      : this.state.amount > this.state.allowedAmount
      ? "approve"
      : this.state.amount <= 0
      ? "invalid"
      : this.state.amount > this.state.balance
      ? "insufficient"
      : "buy";

    let farmStatus = !this.props.wallet.account
      ? "connect"
      : this.state.broadcasting
      ? "broadcasting"
      : !this.state.withdrawAmount
      ? "enter"
      : this.state.withdrawAmount < 0
      ? "invalid"
      : this.state.withdrawAmount > this.state.farmingAmount
      ? "insufficient"
      : "buy";

    let claimStatus = !this.props.wallet.account
      ? "connect"
      : this.state.broadcasting
      ? "broadcasting"
      : "claim";

    return (
      <>
        <main className="main mb-5 ps-lg-3">
          <div className="container">
            <div className="row">
              <div className="col mt-3">
                <div class="gap-1" />
                <h1 className="text-3 fw-bold text-white">Farming</h1>
                <p class="title-description">Farming LP to recieve $LUCKY</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="main-app">
                <div className="tab mb-4 mx-auto">
                  <div
                    className={
                      "tab-item " + (this.state.tab === "buy" ? "active" : "")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ tab: "buy" });
                    }}
                  >
                    Farming
                  </div>
                  <div
                    className={
                      "tab-item " +
                      (this.state.tab === "withdraw" ? "active" : "")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ tab: "withdraw" });
                    }}
                  >
                    Withdraw
                  </div>
                  <div
                    className={
                      "tab-item " + (this.state.tab === "claim" ? "active" : "")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ tab: "claim" });
                    }}
                  >
                    Claim LUCKY
                  </div>
                </div>
                {this.state.tab === "buy" && (
                  <>
                    <div className="card bg-img-1">
                      <div className="card-body text-center">
                        <div className="card-title fs-5 fw-bold">
                          Farming LP
                        </div>

                        <div className="exchange-form border-item">
                          <div className="input-info">
                            <div className="input-token ">
                              <img
                                className="img-inline me-1"
                                src={Logos["LP"]}
                              />
                              LP token
                            </div>
                            <input
                              className="input-detail monospace"
                              inputMode="decimal"
                              autoComplete="off"
                              autoCorrect="off"
                              type="number"
                              pattern="^[0-9]*[.,]?[0-9]*$"
                              placeholder="0.0"
                              minLength="1"
                              maxLength="79"
                              spellCheck="false"
                              value={this.state.amount}
                              onChange={this.handleAmountChange}
                            />
                          </div>

                          <div className="balance text-start text-secondary">
                            Balance{" "}
                            {this.state.balance ? this.state.balance : "0"} LP{" "}
                            {this.state.balance !== this.state.amount && (
                              <a
                                className="pointer text-danger"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    amount: this.state.balance,
                                  });
                                }}
                              >
                                (Max)
                              </a>
                            )}
                          </div>
                        </div>

                        {/* <div className="middle-info">
                          <span className="rate-info monospace">
                            <svg
                              className="me-1 text-secondary"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 8V13"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 6C2.75 7.67 2 9.75 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C10.57 2 9.2 2.3 7.97 2.85"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.9941 16H12.0031"
                                stroke="#292D32"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>{" "}
                            1 AUR = {this.state.tokenPrice} USDT
                          </span>
                          <span className="arrow-down">
                            <img src={arrow} /> 
                          </span>
                        </div> */}
                        <div className="gap-1"></div>
                        <div className="mt-3 d-grid">
                          {status === "connect" && (
                            <a
                              className="btn btn-lg btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#ConnectWalletModal"
                            >
                              Connect wallet
                            </a>
                          )}

                          {status === "broadcasting" && (
                            <a className="btn btn-lg btn-success disabled">
                              Sending...
                            </a>
                          )}

                          {status === "approve" && (
                            <a
                              className="btn btn-lg btn-info"
                              onClick={(event) => {
                                event.preventDefault();
                                this.approve();
                              }}
                            >
                              Approve LP token
                            </a>
                          )}

                          {status === "approving" && (
                            <a className="btn btn-lg btn-info disabled">
                              Approving...
                            </a>
                          )}

                          {status === "enter" && (
                            <a className="btn btn-lg">Enter amount</a>
                          )}

                          {status === "insufficient" && (
                            <a className="btn btn-lg">
                              <span className="text-danger">
                                Insufficient LP Token
                              </span>
                            </a>
                          )}
                          {status === "invalid" && (
                            <a className="btn btn-lg">
                              <span className="text-danger">
                                Invalid amount
                              </span>
                            </a>
                          )}
                          {status === "buy" && (
                            <a
                              className="btn btn-lg btn-success"
                              onClick={(event) => {
                                event.preventDefault();
                                this.farm();
                              }}
                            >
                              Farming
                            </a>
                          )}
                        </div>

                        {this.state.tempHistory.length > 0 && (
                          <TempHistory tempHistory={this.state.tempHistory} />
                        )}
                      </div>
                    </div>

                    {this.state.errMess && (
                      <div className="card bg-img-1 text-danger mt-3">
                        <div className="card-body">
                          <div className="card-text d-flex justify-content-between">
                            <span>
                              <strong>Error:</strong> {this.state.errMess}
                            </span>
                            <button
                              type="button"
                              className="btn-close"
                              aria-label="Close"
                              onClick={(event) => {
                                event.preventDefault();
                                this.clearMess();
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    )}

                    <History
                      wallet={this.props.wallet}
                      historyData={this.state.historyArray}
                      locked="true"
                    />
                  </>
                )}

                {this.state.tab === "withdraw" && (
                  <>
                    <div className="card bg-img-1">
                      <div className="card-body text-center">
                        <div className="card-title fs-5 fw-bold">Withdraw</div>

                        <div className="exchange-form border-item">
                          <div className="input-info">
                            <div className="input-token ">
                              <img
                                className="img-inline me-1"
                                src={Logos["LP"]}
                              />
                              LP token
                            </div>
                            <input
                              className="input-detail monospace"
                              inputMode="decimal"
                              autoComplete="off"
                              autoCorrect="off"
                              type="number"
                              pattern="^[0-9]*[.,]?[0-9]*$"
                              placeholder="0.0"
                              minLength="1"
                              maxLength="79"
                              spellCheck="false"
                              value={this.state.withdrawAmount}
                              onChange={this.handleWithdrawAmountChange}
                            />
                          </div>

                          <div className="balance text-start text-secondary">
                            Farming{" "}
                            {this.state.farmingAmount
                              ? this.state.farmingAmount
                              : "0"}{" "}
                            LP{" "}
                            {this.state.farmingAmount !==
                              this.state.withdrawAmount && (
                              <a
                                className="pointer text-danger"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    withdrawAmount: this.state.farmingAmount,
                                  });
                                }}
                              >
                                (Max)
                              </a>
                            )}
                          </div>
                        </div>

                        <div className="gap-1"></div>
                        <div className="mt-3 d-grid">
                          {farmStatus === "connect" && (
                            <a
                              className="btn btn-lg btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#ConnectWalletModal"
                            >
                              Connect wallet
                            </a>
                          )}

                          {farmStatus === "broadcasting" && (
                            <a className="btn btn-lg btn-success disabled">
                              Sending...
                            </a>
                          )}

                          {farmStatus === "approve" && (
                            <a
                              className="btn btn-lg btn-info"
                              onClick={(event) => {
                                event.preventDefault();
                                this.approve();
                              }}
                            >
                              Approve LP token
                            </a>
                          )}

                          {farmStatus === "approving" && (
                            <a className="btn btn-lg btn-info disabled">
                              Approving...
                            </a>
                          )}

                          {farmStatus === "enter" && (
                            <a className="btn btn-lg">Enter amount</a>
                          )}
                          {farmStatus === "invalid" && (
                            <a className="btn btn-lg ">
                              <span className="text-danger">
                                Invalid amount
                              </span>
                            </a>
                          )}
                          {farmStatus === "insufficient" && (
                            <a className="btn btn-lg">
                              <span className="text-danger">
                                Insufficient LP Token
                              </span>
                            </a>
                          )}
                          {farmStatus === "buy" && (
                            <a
                              className="btn btn-lg btn-success"
                              onClick={(event) => {
                                event.preventDefault();
                                this.requestWithdraw();
                              }}
                            >
                              Claim & Withdraw
                            </a>
                          )}
                        </div>
                        {this.state.tempHistory.length > 0 && (
                          <TempHistory tempHistory={this.state.tempHistory} />
                        )}
                      </div>
                    </div>

                    {this.state.errMess && (
                      <div className="card bg-img-1 text-danger mt-3">
                        <div className="card-body">
                          <div className="card-text d-flex justify-content-between">
                            <span>
                              <strong>Error:</strong> {this.state.errMess}
                            </span>
                            <button
                              type="button"
                              className="btn-close"
                              aria-label="Close"
                              onClick={(event) => {
                                event.preventDefault();
                                this.clearMess();
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    )}

                    <WithdrawHistory
                      withdrawLP={this.withdrawLP}
                      wallet={this.props.wallet}
                      historyData={this.state.withdrawHistoryArray}
                      locked="true"
                    />
                  </>
                )}

                {this.state.tab === "claim" && (
                  <>
                    <div className="card bg-img-1">
                      <div className="card-body text-center">
                        <div className="card-title fs-5 fw-bold">Available</div>
                        {/* <div className="gap-1"></div> */}
                        <div className="exchange-form border-item">
                          <div className="input-info  pb-0">
                            <div className="input-token">
                              <img
                                className="img-inline me-1"
                                src={Logos["LUCKY"]}
                              />
                              LUCKY
                            </div>

                            <div className="fs-3 input-detail  monospace text-end ms-auto">
                              {numeral(this.state.luckyClaimable).format(
                                "0,0.[00000]"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="exchange-form border-item">
                          <div className="input-info  pb-0">
                            <div className="input-token">
                              <img
                                className="img-inline me-1"
                                src={Logos["NFL"]}
                              />
                              NFL
                            </div>

                            <div className="fs-3 input-detail monospace text-end ms-auto">
                              {numeral(this.state.nflClaimable).format(
                                "0,0.[00000]"
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 d-grid">
                          {claimStatus === "connect" && (
                            <a
                              className="btn btn-lg btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#ConnectWalletModal"
                            >
                              Connect wallet
                            </a>
                          )}
                          {claimStatus === "broadcasting" && (
                            <a className="btn btn-lg btn-success disabled">
                              Requesting...
                            </a>
                          )}

                          {claimStatus === "claim" && (
                            <a
                              className="btn btn-lg btn-success"
                              onClick={(e) => {
                                e.preventDefault();
                                this.claim();
                              }}
                            >
                              Claim
                            </a>
                          )}
                        </div>
                        {this.state.tempHistory.length > 0 && (
                          <TempHistory tempHistory={this.state.tempHistory} />
                        )}
                      </div>
                    </div>

                    {this.state.errMess && (
                      <div className="card bg-img-1 text-danger mt-3">
                        <div className="card-body">
                          <div className="card-text d-flex justify-content-between">
                            <span>
                              <strong>Error:</strong> {this.state.errMess}
                            </span>
                            <button
                              type="button"
                              className="btn-close"
                              aria-label="Close"
                              onClick={(event) => {
                                event.preventDefault();
                                this.clearMess();
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    )}

                    <ClaimHistory
                      wallet={this.props.wallet}
                      historyData={this.state.claimHistoryArray}
                      locked="true"
                    />
                  </>
                )}
              </div>
            </div>
            {/* <div className="gap-6" />
            amount {this.state.withdrawAmount}
            <br />
            allowed {this.state.allowedAmount}
            <br />
            Balance {this.state.balance}
            <hr />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.approve();
              }}
            >
              Approve
            </a>
            <br /> */}
            <div className="gap-6" />
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(FarmLP);
