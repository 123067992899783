import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import logo from "../assets/images/logo.svg";
import { INFURA } from "./constants";
import $ from "jquery";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import Home from "./Home";
import MyCards from "./MyCards";
import OpenChest from "./OpenChest";
import NFTMarket from "./NFTMarket";
import FarmLP from "./FarmLP";
import Terms from "./views/Terms";
import bg from "../assets/images/bg.mp4";
import AccountInfoModal from "./views/AccountInfoModal";
import ConnectWalletModal from "./views/ConnectWalletModal";
import SideBar from "./views/SideBar";

import { shortAddress } from "./utils/";
import Web3 from "web3";
import numeral from "numeral";

// import { Navigation } from "react-minimal-side-navigation";
// import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chainChecker: "uncheck",
    };
  }

  componentWillMount() {
    this.loadWeb3();
  }

  componentDidMount() {
    this.loadWeb3();

    // $(document).scroll(function () {
    //   var $nav = $("header");
    //   $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
    //   $(".sidebar").toggleClass(
    //     "scrolled",
    //     $(this).scrollTop() > $nav.height()
    //   );
    // });

    var windowsize = $(window).width();
    $(window).resize(function () {
      windowsize = $(window).width();
      $(".app-container").toggleClass("small-screen", windowsize < 992);
    });
    $(".app-container").toggleClass("small-screen", windowsize < 992);
  }

  loadWeb3() {
    if (window.ethereum) {
      //incase browser suport ETH
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      //incase re-call
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      //In the rest case
      window.web3 = new Web3(new Web3.providers.HttpProvider(INFURA));
    }
  }

  componentDidUpdate(prevProps) {}

  render() {
    const wallet = this.props.wallet;
    return (
      <Router>
        <header className="p-3 text-white sticky-top">
          <div className="">
            <div className="d-flex align-items-center">
              <Link to="/" className="d-flex align-items-center">
                <img src={logo} alt="logo" width="150" />
              </Link>
              <div className="text-end ms-auto">
                {wallet.status === "connected" ? (
                  <>
                    {wallet.chainId === 4 ? (
                      <span className="btn btn-sm btn-soft-info me-2 d-none d-md-inline-block">
                        Rinkeby
                      </span>
                    ) : (
                      ""
                    )}

                    <div className="btn-group btn-group-sm" role="group">
                      <div className="btn-group btn-group-sm" role="group">
                        <a
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-primary text-white dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="monospace">
                            {shortAddress(wallet.account)}
                          </span>{" "}
                          •{" "}
                          {numeral(wallet.balance / 10 ** 18).format(
                            "0,0.[0000]"
                          )}{" "}
                          BNB
                        </a>
                        <ul
                          className="dropdown-menu mt-1 dropdown-menu-end"
                          aria-labelledby="btnGroupDrop1"
                        >
                          <li className="mx-2">
                            <a
                              className="dropdown-item rounded d-flex justify-content-between"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#accountInfoModal"
                            >
                              <span>Info</span>
                              <span className="ms-1">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 8V13"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 6C2.75 7.67 2 9.75 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C10.57 2 9.2 2.3 7.97 2.85"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.9941 16H12.0031"
                                    stroke="#292D32"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </a>
                          </li>
                          <li className="mx-2">
                            <a
                              className="dropdown-item rounded d-flex justify-content-between"
                              href="#"
                              onClick={() => {
                                wallet.reset();
                              }}
                            >
                              <span>Disconnect</span>
                              <span className="ms-1">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.9902 10.0119L14.8302 9.17188"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.16992 14.8281L11.9199 12.0781"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M14.8299 14.8319L9.16992 9.17188"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 6C2.75 7.67 2 9.75 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C10.57 2 9.2 2.3 7.97 2.85"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#ConnectWalletModal"
                    href="#"
                    className="btn btn-primary text-white"
                  >
                    Unlock Wallet
                  </a>
                )}

                <a
                  className="btn pe-0 d-inline-block d-lg-none"
                  onClick={(e) => {
                    e.preventDefault();
                    $(".sidebar").toggleClass("hide");
                    $(".hamber").toggleClass("change");
                  }}
                >
                  <div class="hamber">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </header>

        <div className="app-container">
          <SideBar />
          <Switch>
            <Route exact path="/">
              <Home wallet={this.props.wallet} />
            </Route>
            <Route exact path="/my-cards">
              <MyCards wallet={this.props.wallet} />
            </Route>
            <Route exact path="/open-chest">
              <OpenChest wallet={this.props.wallet} />
            </Route>
            <Route exact path="/NFT-market">
              <NFTMarket wallet={this.props.wallet} />
            </Route>
            <Route exact path="/farming">
              <FarmLP wallet={this.props.wallet} />
            </Route>
          </Switch>
        </div>

        <AccountInfoModal wallet={this.props.wallet} />
        <ConnectWalletModal wallet={this.props.wallet} />
        <Terms />

        <video
          src={bg}
          className="z-1"
          autoPlay="autoplay"
          loop
          playsInline="playsinline"
          muted
        ></video>

        <footer className="footer mt-auto my-3 ">
          <div className="container-fluid my-4 ">
            <p className="text-muted small pointer">
              {wallet.status === "connected" ? (
                <span>
                  {" "}
                  {shortAddress(wallet.account)} ({wallet.networkName})
                  <span
                    onClick={() => {
                      wallet.reset();
                    }}
                  >
                    ﹒ Disconnect
                  </span>
                </span>
              ) : (
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#ConnectWalletModal"
                >
                  Unlock Wallet
                </a>
              )}
            </p>
          </div>
          <ReactTooltip />
          <img
            className="nk-footer-top-corner"
            src="https://wp.nkdev.info/godlike/wp-content/themes/godlike/assets/images/footer-corner.png"
            alt=""
          />
        </footer>
      </Router>
    );
  }
}
