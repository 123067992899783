import React from "react";
import { getContract } from "../utils/ContractLoader";
import Card3D from "./Card3D";
import cardfront from "../../assets/cards/cardfront.jpg";
import logo from "../../assets/images/logo.svg";
import { cardObj } from "../utils";

export default class MyCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      nftContract: null,
      nftContractAddress: null,
      cardList: [],
    };
  }

  async componentWillMount() {
    let nft = getContract("nftContract");

    await this.setState({
      nftContract: nft.contract,
      nftContractAddress: nft.contractAddress,
    });
    this.getCardID();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.wallet !== this.props.wallet) {
      if (nextProps.wallet.status === "connected") {
        this.getCardID();
      } else {
        this.setState({
          cardList: [],
        });
      }
    }
  }

  async componentDidMount() {}
  async getCardID() {
    this.setState({ loading: true });
    let account = this.props.wallet.account;
    let contract = this.state.nftContract;
    // console.log(this.state.contract);

    let balanceOf = await contract.methods.balanceOf(account).call();
    // console.log("balance " + balanceOf);

    let promiseTokenID = [];
    let cardList = [];

    for (let i = 0; i < balanceOf; i++) {
      promiseTokenID.push(
        contract.methods.tokenOfOwnerByIndex(account, i).call()
      );
    }

    let tokenIDList = await Promise.all(promiseTokenID);
    // console.log(cardList);

    let promiseCardID = [];
    for (let i = 0; i < tokenIDList.length; i++) {
      promiseCardID.push(contract.methods.tokenURI(tokenIDList[i]).call());
    }

    cardList = await Promise.all(promiseCardID);

    // console.log(cardList);
    this.setState({ cardList, loading: false });
  }
  render() {
    return (
      <>
        {this.state.cardList.length !== 0 &&
          !this.state.loading &&
          this.state.cardList.map((i, index) => (
            // <div key={index} className="col-6 col-md-3 col-lg-2">
            //   <span
            //     className={"fw-bold text-uppercase text-" + cardObj(i).kind}
            //   >
            //     {cardObj(i).kind}
            //     {i}
            //   </span>

            //   <Card3D
            //     className="card-result"
            //     front={require("../../assets/cards/" + i + ".jpg").default}
            //     back={cardfront}
            //   />
            //   <div className="gap-1" />
            // </div>

            <div key={index} className="col-sm-6 col-lg-6 col-xl-3 py-3">
              <div className="card bg-img-1">
                <div className="card-header d-flex justify-content-between">
                  <img src={logo} width="100" />
                  <span
                    className={
                      "fw-bold fs-5 text-uppercase text-" + cardObj(i).kind
                    }
                  >
                    {cardObj(i).kind}
                    {cardObj(i).cardID}
                  </span>
                </div>
                <div className="card-body py-4 d-flex justify-content-center">
                  <Card3D
                    front={
                      require("../../assets/cards/" +
                        cardObj(i).cardID +
                        ".jpg").default
                    }
                    back={cardfront}
                  />
                </div>
                <div className="card-footer d-flex justify-content-between small">
                  {/* <span className="buy-option text-muted">
                      <a>
                        <span className="text-muted">Buy on </span>
                        <span className="fw-bold">
                          <img src={market} className="img-inline mx-1" />
                          NFTLengends Market
                        </span>
                      </a>
                    </span>
                    <span className="text-muted ">max. issue {i.limit}</span> */}
                </div>
              </div>
            </div>
          ))}

        {!this.state.cardList.length && !this.state.loading && (
          <div class="">Empty</div>
        )}
        {this.state.loading && (
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        )}
      </>
    );
  }
}
