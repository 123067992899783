export const Logos = {
  BUSD: require("./busd.svg").default,
  USDT: require("./USDT.svg").default,
  HUSD: require("./HUSD.svg").default,
  HT: require("./HT.svg").default,
  USDC: require("./usdc.png").default,
  ETH: require("./ETH.png").default,
  DAI: require("./dai.png").default,
  BNB: require("./bnb.png").default,
  NFL: require("./nfl.svg").default,
  LP: require("./LP.svg").default,
  LUCKY: require("./lucky.svg").default,
};

export const LogosWhite = {
  UNI: require("./white/uniswap.svg").default,
  BNB: require("./white/binance.svg").default,
  HT: require("./white/huobi.svg").default,
  metamask: require("./white/metamask.png").default,
  trust: require("./white/trust.svg").default,
};
