import React from "react";
import { cardObj } from "../utils";
import Card3D from "./Card3D";
import cardfront from "../../assets/cards/cardfront.jpg";
import { Link } from "react-router-dom";

export default class ResultOpenChestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let wallet = this.props.wallet;
    return (
      <div
        className="modal fade"
        id="ResultOpenChestModal"
        tabIndex="-1"
        aria-labelledby="ResultOpenChestModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content card bg-img-1">
            <div className="modal-header mx-auto">
              <h4 className="modal-title">Your Chest</h4>
            </div>
            <div className="modal-body row d-flex justify-content-center">
              {/* <div className="d-none d-lg-block col-1"></div> */}
              {this.props.cardList.length !== 0 &&
                this.props.cardList.map((i, index) => (
                  <div key={index} className="col-6 col-md-3 col-lg-2">
                    <span
                      className={
                        "fw-bold text-uppercase text-" + cardObj(i).kind
                      }
                    >
                      {cardObj(i).kind}
                      {i}
                    </span>
                    {/* <img
                    src={require("../../assets/cards/" + i + ".jpg").default}
                    className="img-result"
                  /> */}
                    <Card3D
                      className="card-result"
                      front={
                        require("../../assets/cards/" + i + ".jpg").default
                      }
                      back={cardfront}
                    />
                    <div className="gap-1" />
                  </div>
                ))}

              {!this.props.cardList.length && (
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
            <div className="modal-footer">
              {/* <Link
                to="/my-cards"
                className="btn btn-danger"
                // data-bs-dismiss="modal"
              >
                My cards
              </Link> */}
              <button className="btn btn-link" data-bs-dismiss="modal">
                Close
              </button>
              {/* <button
                    onClick={() => { $('#accountInfoModal').modal('hide') }}
                    className="btn btn-brand">
                    Close
            </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
