import React from "react";
import {
  TOKEN_INFO,
  TOTAL_APPROVE,
  CONFIRM_NUMBER,
  EXPLORER,
  TIMELOCK,
} from "../constants";
import $ from "jquery";
import { shortAddress, loadAccountBalance, safeAmount } from "../utils/";
import arrow from "../../assets/images/svg/arrow-down-1.svg";
import lock from "../../assets/images/svg/lock.svg";
import { Logos } from "../../assets/CoinLogos";
import moment from "moment";
import numeral from "numeral";

export default class WithdrawHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAur: true,
    };
  }

  calStatus(timestamp, status) {
    // console.log(timestamp, status);
    if (Date.now() - (parseInt(timestamp) + TIMELOCK) * 1000 <= 0) {
      return "pending";
    } else {
      return status === "1" ? "withdrawn" : "available";
    }
  }

  // withdrawLP(id) {
  //   this.props.withdrawLP(id);
  // }

  render() {
    let wallet = this.props.wallet;
    // let history = this.props.hostory;

    return (
      <>
        {this.props.historyData.length !== 0 && (
          <div className="card mt-3">
            <div className="card-body text-center">
              <div className="card-title fs-5 fw-bold">Withdraw history</div>
              {this.props.historyData.length === 0 && (
                <div className="card-text py-4 fs-italic text-secondary">
                  Empty
                </div>
              )}
              {this.props.historyData.length > 0 &&
                this.props.historyData.reverse().map((i, index) => (
                  <div key={index} className="border-item">
                    <div className="d-flex justify-content-between">
                      {/* {this.props.historyData.length - index - 1}{" "} */}
                      {this.calStatus(i.timestamp, i.status) === "pending" && (
                        <span className="">Requested</span>
                      )}
                      {this.calStatus(i.timestamp, i.status) ===
                        "available" && (
                        <span className="text-brand">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.withdrawLP(
                                this.props.historyData.length - index - 1
                              );
                            }}
                          >
                            Withdraw
                          </a>
                        </span>
                      )}
                      {this.calStatus(i.timestamp, i.status) ===
                        "withdrawn" && (
                        <span className="text-success">Complete</span>
                      )}
                      <span
                        className="d-flex align-items-center monospace pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ displayAur: !this.state.displayAur });
                        }}
                      >
                        {safeAmount(i.amount, 18) + " LP Token"}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between small text-secondary">
                      <span>
                        {this.state.displayAur
                          ? moment(parseInt(i.timestamp) * 1000).fromNow()
                          : moment(parseInt(i.timestamp) * 1000).format("LLL")}
                      </span>

                      <span className="d-flex align-items-center">
                        {this.calStatus(i.timestamp, i.status)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    );
  }
}
