import React from "react";
import { loadAccountBalance } from "../utils";
import { Logos } from "../../assets/CoinLogos";
import { InlineLoader } from "./ViewComponents";
import numeral from "numeral";
import { EXPLORER } from "../constants";
import { BsArrowUpRight } from "react-icons/bs";
import { NavLink, Link } from "react-router-dom";

export default class AccountInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balanceNFL: null,
      balanceLUCKY: null,
      balanceLP: null,
    };
  }

  async componentWillMount() {
    // let [balanceNFL] = await Promise.all([
    //   loadAccountBalance("NFL", this.props.wallet.account),
    // ]);
    // this.setState({
    //   balanceNFL,
    // });

    this.loadAllBalance(this.props.wallet.account);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.wallet !== this.props.wallet) {
      this.loadAllBalance(nextProps.wallet.account);
    }
  }

  async loadAllBalance(account) {
    if (account) {
      let [balanceNFL, balanceLUCKY, balanceLP] = await Promise.all([
        loadAccountBalance("NFL", account),
        loadAccountBalance("LUCKY", account),
        loadAccountBalance("LP", account),
      ]);
      this.setState({
        balanceNFL,
        balanceLUCKY,
        balanceLP,
      });
    } else {
      this.setState({
        balanceNFL: 0,
        balanceLUCKY: 0,
        balanceLP: 0,
      });
    }
  }

  render() {
    return (
      <div
        className="modal fade"
        id="accountInfoModal"
        tabIndex="-1"
        aria-labelledby="accountInfoModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content card">
            <div className="modal-header mx-auto">
              <h4 className="modal-title">Your balance</h4>
            </div>
            <div className="modal-body text-center">
              <div className="border-item">
                <div className="d-flex justify-content-between">
                  <span className="d-flex align-items-center">
                    <img
                      src={Logos["BNB"]}
                      className="img-inline me-1"
                      alt="BNB"
                    />{" "}
                    BNB
                  </span>
                  <span className="number mb-0">
                    {numeral(this.props.wallet.balance / 10 ** 18).format(
                      "0,0.[0000]"
                    )}
                  </span>
                </div>
              </div>
              <div className="border-item">
                <div className="d-flex justify-content-between">
                  <span className="d-flex align-items-center">
                    <img
                      src={Logos["NFL"]}
                      className="img-inline  me-1"
                      alt="NFL"
                    />{" "}
                    NFL
                  </span>
                  <span className="number mb-0">
                    {this.state.balanceNFL === null ? (
                      <InlineLoader />
                    ) : (
                      numeral(this.state.balanceNFL).format("0,0.[00]")
                    )}
                  </span>
                </div>
              </div>
              <div className="border-item">
                <div className="d-flex justify-content-between">
                  <span className="d-flex align-items-center">
                    <img
                      src={Logos["LUCKY"]}
                      className="img-inline  me-1"
                      alt="NFL"
                    />{" "}
                    LUCKY
                  </span>
                  <span className="number mb-0">
                    {this.state.balanceLUCKY === null ? (
                      <InlineLoader />
                    ) : (
                      numeral(this.state.balanceLUCKY).format("0,0.[00]")
                    )}
                  </span>
                </div>
              </div>
              <div className="border-item">
                <div className="d-flex justify-content-between">
                  <span className="d-flex align-items-center">
                    <img
                      src={Logos["LP"]}
                      className="img-inline  me-1"
                      alt="NFL"
                    />{" "}
                    LP
                  </span>
                  <span className="number mb-0">
                    {this.state.balanceLP === null ? (
                      <InlineLoader />
                    ) : (
                      numeral(this.state.balanceLP).format("0,0.[00]")
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <span className="me-auto text-sm">
                <a
                  href={EXPLORER + "address/" + this.props.wallet.account}
                  target="_blank"
                  rel="noreferrer"
                  className=""
                >
                  BSCscan
                  <BsArrowUpRight />
                </a>
              </span>
              <button
                onClick={() => {
                  this.props.wallet.reset();
                  // $("#accountInfoModal").modal("hide");
                }}
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Disconnect
              </button>
              {/* <button
                    onClick={() => { $('#accountInfoModal').modal('hide') }}
                    className="btn btn-brand">
                    Close
            </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
