import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ConnectCard from "./views/ConnectCard";
import AppCard from "./views/AppCard";
import Card3D from "./views/Card3D";
import card from "../assets/cards/77.jpg";
import cardfront from "../assets/cards/cardfront.jpg";

import market from "../assets/images/market.svg";

import logo from "../assets/images/logo.svg";

import { cardObj } from "./utils";
class NFTMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayShow: [],
    };
  }

  componentWillMount() {
    let arrayShow = [];
    for (let i = 1; i <= 28; i++) {
      arrayShow.push(cardObj(i));
    }

    this.setState({ arrayShow });
  }

  loadMore() {
    let arrayShow = this.state.arrayShow;
    let arrayLength = this.state.arrayShow.length;
    for (let i = 1; i <= 12 && i + arrayLength <= 105; i++) {
      arrayShow.push(cardObj(arrayLength + i));
    }
    this.setState({ arrayShow });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    let wallet = this.props.wallet;
    return (
      <main className="main mb-5 ps-lg-3">
        <div className="container">
          <div className="row">
            <div className="col mt-3">
              <div class="gap-1" />
              <h1 className="text-3 fw-bold text-white">NFT Market</h1>
              <p class="title-description">Trade your cardfights</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-mb-5">
            <div className="col">Coming soon</div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(NFTMarket);
