import React from "react";
import walletconnect from "../../assets/images/walletconnect-banner.svg";
import metamask from "../../assets/images/metamask-fox-wordmark-horizontal.svg";

export default class ConnectWalletModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let wallet = this.props.wallet;
    return (
      <div
        className="modal fade"
        id="ConnectWalletModal"
        tabIndex="-1"
        aria-labelledby="ConnectWalletModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content card">
            <div className="modal-body text-center">
              <div
                className="border-item pointer py-4"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  wallet.connect();
                }}
              >
                <img src={metamask} width="150" />
              </div>
              <div
                className="border-item pointer py-4"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  wallet.connect("walletconnect");
                }}
              >
                <img src={walletconnect} width="230" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
