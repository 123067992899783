import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Card3D from "./views/Card3D";
import card from "../assets/cards/77.jpg";
import cardfront from "../assets/cards/cardfront.jpg";

import market from "../assets/images/market.svg";

import logo from "../assets/images/logo.svg";

import { cardObj } from "./utils";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayShow: [],
    };
  }

  componentWillMount() {
    let arrayShow = [];
    for (let i = 1; i <= 28; i++) {
      arrayShow.push(cardObj(i));
    }

    this.setState({ arrayShow });
  }

  loadMore() {
    let arrayShow = this.state.arrayShow;
    let arrayLength = this.state.arrayShow.length;
    for (let i = 1; i <= 12 && i + arrayLength <= 105; i++) {
      arrayShow.push(cardObj(arrayLength + i));
    }
    this.setState({ arrayShow });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    let wallet = this.props.wallet;
    return (
      <main className="main mb-5 ps-lg-3">
        <div className="container">
          <div className="row">
            <div className="col mt-3">
              <div class="gap-1" />
              <h1 className="text-3 fw-bold text-white">
                NFT Cardfight gallery
              </h1>
              <p class="title-description">All of 105 cardfights</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-mb-5">
            <div className="gap-3" />

            {this.state.arrayShow.map((i, index) => (
              <div key={index} className="col-sm-6 col-lg-6 col-xl-3 py-3">
                <div className="card stacked-card bg-img-1">
                  <div className="card-header d-flex justify-content-between">
                    <img src={logo} width="100" />
                    <span
                      className={"fw-bold fs-5 text-uppercase text-" + i.kind}
                    >
                      {i.kind} #{i.cardID}
                    </span>
                  </div>
                  <div className="card-body py-4 d-flex justify-content-center">
                    <Card3D
                      front={
                        require("../assets/cards/" + i.cardID + ".jpg").default
                      }
                      back={cardfront}
                    />
                  </div>
                  <div className="card-footer d-flex justify-content-between small">
                    <span className="buy-option text-muted">
                      <a>
                        <span className="text-muted">Buy on </span>
                        <span className="fw-bold">
                          <img src={market} className="img-inline mx-1" />
                          NFTLengends Market
                        </span>
                      </a>
                    </span>
                    <span className="text-muted ">max. issue {i.limit}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="gap-3"></div>
            {this.state.arrayShow.length < 105 && (
              <div className="col d-flex justify-content-center">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.loadMore();
                  }}
                  className="btn btn-lg btn-primary"
                >
                  Loadmore
                </a>
              </div>
            )}
            <div className="gap-3"></div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(Home);
