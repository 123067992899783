import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getContract } from "./utils/ContractLoader";

import { Logos } from "../assets/CoinLogos";
import {
  shortAddress,
  loadAccountBalance,
  safeAmount,
  convert,
} from "./utils/";

import chest0 from "../assets/images/chest0.png";
import chest1 from "../assets/images/chest1.png";
import chest2 from "../assets/images/chest2.png";
import chest3 from "../assets/images/chest3.png";
import TempHistory from "./views/TempHistory";
import ResultOpenChestModal from "./views/ResultOpenChestModal";

// RareChest
// import RareChest from "./views/RareChest";

import $ from "jquery";

import { Modal } from "bootstrap";

import {
  TOKEN_INFO,
  TOTAL_APPROVE,
  CONFIRM_NUMBER,
  EXPLORER,
  FEE,
  LUCKY_REQUIRE,
} from "./constants";

// import OpenChestModal from "../views/OpenChestModal";

class OpenChest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null, //* CONTRACT
      contractAddress: null, //* CONTRACT ADDRESS
      nftContract: null,
      nftContractAddress: null,
      approveStatus: false,
      approveLuckyStatus: false,
      allowedAmount: 0,
      allowedLuckyAmount: 0,

      //* Balance
      balanceNFL: null,
      balanceLUCKY: null,

      //* History
      tempHistory: [],
      historyArray: [],
      errMess: "",
      amountNFL: 25,
      tokenIDList: [],
      cardList: [],
      activeChest: "",
    };
  }

  // handleCardList = (cardList) => {
  //   this.setState({ cardList });
  // };

  async componentWillMount() {
    await this.setState(getContract("luckyChest"));
    let nft = getContract("nftContract");
    await this.setState({
      nftContract: nft.contract,
      nftContractAddress: nft.contractAddress,
    });

    // console.log(this.state.nftContract, this.state.nftContractAddress);

    this.getUserInfo();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.wallet !== this.props.wallet) {
      if (nextProps.wallet.status === "connected") {
        this.getUserInfo();
      } else {
        this.setState({
          balanceNFL: 0,
        });
      }
    }
  }

  async componentDidMount() {}

  async getUserInfo() {
    var account = this.props.wallet.account;
    var contractAddress = this.state.contractAddress;
    var contract = this.state.contract;

    if (account) {
      //* GET TOKENS BALANCE
      let [balanceNFL, balanceLUCKY] = await Promise.all([
        loadAccountBalance("NFL", account),
        loadAccountBalance("LUCKY", account),
      ]);

      const web3 = window.web3;
      var abi = require("human-standard-token-abi");
      let contractNFL = new web3.eth.Contract(abi, TOKEN_INFO["NFL"].address);
      let contractLUCKY = new web3.eth.Contract(
        abi,
        TOKEN_INFO["LUCKY"].address
      );

      // console.log(contract, contractAddress);
      let allowedAmount = await contractNFL.methods
        .allowance(account, contractAddress)
        .call();
      allowedAmount = parseInt(allowedAmount) / 10 ** TOKEN_INFO["NFL"].decimal;
      // console.log("allowedAmount", allowedAmount);

      let allowedLuckyAmount = await contractLUCKY.methods
        .allowance(account, contractAddress)
        .call();

      allowedLuckyAmount =
        parseInt(allowedLuckyAmount) / 10 ** TOKEN_INFO["LUCKY"].decimal;
      // console.log("allowedLuckyAmount", allowedLuckyAmount);

      this.setState({
        balanceNFL,
        balanceLUCKY,
        allowedAmount,
        allowedLuckyAmount,
      });
    }
  }

  async openRareChest() {
    await this.setState({ tokenIDList: [] });

    const web3 = window.web3;

    let amountDecimal = convert(
      this.state.amountNFL * 10 ** TOKEN_INFO["NFL"].decimal
    ).toString();

    let data = [];
    for (var i = 0; i < 10; i++) {
      var random = Math.floor(1000000000 + Math.random() * 9999999999);
      data.push(random);
    }

    let fee = FEE * 10 ** 18;

    this.setState({ broadcasting: true });
    this.state.contract.methods
      .buyCard(amountDecimal, data)
      .send({
        from: this.props.wallet.account,
        value: fee,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // $("#loadingModal").modal("show");
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Open Rare Chest",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );

          this.setState({ tokenIDList: this.state.tokenIDList.concat(data) });
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();

          this.getCardIDList();
          // console.log("cardList", this.state.cardList);

          this.showModal();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  async openEpicChest() {
    await this.setState({ tokenIDList: [] });

    const web3 = window.web3;

    let amountDecimal = convert(
      this.state.amountNFL * 10 ** TOKEN_INFO["NFL"].decimal
    ).toString();

    let amountLuckyDecimal = convert(
      LUCKY_REQUIRE["epic"] * 10 ** TOKEN_INFO["LUCKY"].decimal
    ).toString();

    let data = [];
    for (var i = 0; i < 10; i++) {
      var random = Math.floor(1000000000 + Math.random() * 9999999999);
      data.push(random);
    }

    let fee = FEE * 10 ** 18;

    this.setState({ broadcasting: true });
    this.state.contract.methods
      .buyCardSR(amountDecimal, amountLuckyDecimal, data)
      .send({
        from: this.props.wallet.account,
        value: fee,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // $("#loadingModal").modal("show");
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Open Epic Chest",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );

          this.setState({ tokenIDList: this.state.tokenIDList.concat(data) });
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();

          this.getCardIDList();
          // console.log("cardList", this.state.cardList);

          this.showModal();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  async openLegendaryChest() {
    await this.setState({ tokenIDList: [] });

    const web3 = window.web3;

    let amountDecimal = convert(
      this.state.amountNFL * 10 ** TOKEN_INFO["NFL"].decimal
    ).toString();

    let amountLuckyDecimal = convert(
      LUCKY_REQUIRE["legendary"] * 10 ** TOKEN_INFO["LUCKY"].decimal
    ).toString();

    let data = [];
    for (var i = 0; i < 10; i++) {
      var random = Math.floor(1000000000 + Math.random() * 9999999999);
      data.push(random);
    }

    let fee = FEE * 10 ** 18;

    this.setState({ broadcasting: true });
    this.state.contract.methods
      .buyCardSSR(amountDecimal, amountLuckyDecimal, data)
      .send({
        from: this.props.wallet.account,
        value: fee,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // $("#loadingModal").modal("show");
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Open Legendary Chest",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );

          this.setState({ tokenIDList: this.state.tokenIDList.concat(data) });
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();

          this.getCardIDList();
          // console.log("cardList", this.state.cardList);

          this.showModal();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  showModal() {
    let bsModal = new Modal($("#ResultOpenChestModal"));
    bsModal.show();
  }

  async getCardIDList() {
    // CardID is URI
    var account = this.props.wallet.account;
    var contract = this.state.nftContract;
    let promise = [];
    let cardList = [];
    for (let i = 0; i < this.state.tokenIDList.length; i++) {
      promise.push(
        contract.methods.tokenURI(this.state.tokenIDList[i].toString()).call()
      );
    }
    cardList = await Promise.all(promise);
    this.setState({ cardList });
  }

  async approve() {
    const web3 = window.web3;
    let abi = require("human-standard-token-abi");
    let stakeTokenContract = new web3.eth.Contract(
      abi,
      TOKEN_INFO["NFL"].address
    );

    let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
    this.setState({ approveStatus: false });

    stakeTokenContract.methods
      .approve(this.state.contractAddress, totalApprove)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // console.log(hash);
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Approve NFL",
        });
        // console.log(this.state.tempHistory);
        this.setState({ approveStatus: "pending" });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          // console.log(receipt.transactionHash);
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";
          this.setState({ approveStatus: "success" });
        }
      })
      .on("error", (error) => {
        this.setState({
          approveStatus: "error",
          errMess: error.message,
        });
      });
  }

  async approveLucky() {
    const web3 = window.web3;
    let abi = require("human-standard-token-abi");
    let stakeTokenContract = new web3.eth.Contract(
      abi,
      TOKEN_INFO["LUCKY"].address
    );

    let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
    this.setState({ approveStatus: false });

    stakeTokenContract.methods
      .approve(this.state.contractAddress, totalApprove)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // console.log(hash);
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Approve LUCKY",
        });
        // console.log(this.state.tempHistory);
        this.setState({ approveStatus: "pending" });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          // console.log(receipt.transactionHash);
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";
          this.setState({ approveStatus: "success" });
        }
      })
      .on("error", (error) => {
        this.setState({
          approveStatus: "error",
          errMess: error.message,
        });
      });
  }

  render() {
    let wallet = this.props.wallet;
    let status = !wallet.account
      ? "connect"
      : this.state.approveStatus === "pending"
      ? "approving"
      : this.state.balanceNFL < this.state.amountNFL
      ? "insufficient"
      : this.state.amountNFL > this.state.allowedAmount
      ? "approve"
      : "open";

    let epicStatus = !wallet.account
      ? "connect"
      : this.state.approveStatus === "pending"
      ? "approving"
      : this.state.balanceNFL < this.state.amountNFL
      ? "insufficientNFL"
      : this.state.balanceLUCKY < LUCKY_REQUIRE["epic"]
      ? "insufficientLUCKY"
      : LUCKY_REQUIRE["epic"] > this.state.allowedLuckyAmount
      ? "approveLUCKY"
      : this.state.amountNFL > this.state.allowedAmount
      ? "approveNFL"
      : "open";

    let legendaryStatus = !wallet.account
      ? "connect"
      : this.state.approveStatus === "pending"
      ? "approving"
      : this.state.balanceNFL < this.state.amountNFL
      ? "insufficientNFL"
      : this.state.balanceLUCKY < LUCKY_REQUIRE["legendary"]
      ? "insufficientLUCKY"
      : LUCKY_REQUIRE["legendary"] > this.state.allowedLuckyAmount
      ? "approveLUCKY"
      : this.state.amountNFL > this.state.allowedAmount
      ? "approveNFL"
      : "open";

    let activeChest = this.state.activeChest;
    return (
      <>
        <main className="main mb-5 ps-lg-3">
          <div className="container">
            <div className="row">
              <div className="col mt-3">
                <div class="gap-1" />
                <h1 className="text-3 fw-bold text-white">
                  Open Treasure Chest
                </h1>
                <p class="title-description">
                  Finding your first companions by opening chests. Each chest
                  has a different chance of getting high valuable cards
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            {/* <div className="row">
              <div class="gap-3"></div>
              <div class="col">
                Balance: {this.state.balanceNFL}
                <br /> Allow: {this.state.allowedAmount}
                <br />
                {this.state.contractAddress}
                <br />
                {wallet.account}
              </div>
            </div>
            <div className="row">
              <div class="gap-3"></div>
              <div class="col">Result: {this.state.cardList}</div>
            </div> */}
            <div className="row">
              <div class="gap-3"></div>
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card  h-100">
                  <div className="card-header d-flex justify-content-between"></div>
                  <div className="card-body d-flex flex-column">
                    <div className="card-text  text-center">
                      <img
                        src={chest0}
                        className={
                          "chest-img " + (activeChest === "rare" ? "hide" : "")
                        }
                        id="rare-chest"
                        onClick={(e) => {
                          e.preventDefault();
                          activeChest !== "rare"
                            ? this.setState({ activeChest: "rare" })
                            : this.setState({ activeChest: "" });
                        }}
                      />
                    </div>
                    <p className="card-text text-center fw-bold text-3 text-r">
                      RARE CHEST
                    </p>

                    <p className="card-text d-flex justify-content-between fw-bold text-1">
                      <span>25 NFL</span>
                      <span>
                        10 cards (C,
                        <span className="text-r">R</span>)
                      </span>
                    </p>
                    <p
                      className={
                        "card-text d-grid " +
                        (activeChest === "rare" ? "d-none" : "")
                      }
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ activeChest: "rare" });
                        }}
                        class="btn btn-lg  btn-primary"
                      >
                        Open chest
                      </a>
                    </p>
                    <div
                      className={
                        "mt-auto " + (activeChest !== "rare" ? "d-none" : "")
                      }
                    >
                      <p className="card-text d-grid gap-2">
                        {status === "connect" && (
                          <a
                            className="btn btn-lg btn-info"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#ConnectWalletModal"
                          >
                            Connect wallet
                          </a>
                        )}
                        {status === "approve" && (
                          <a
                            className="btn btn-lg btn-info"
                            onClick={(event) => {
                              event.preventDefault();
                              this.approve();
                            }}
                          >
                            Approve NFL
                          </a>
                        )}

                        {status === "approving" && (
                          <a className="btn btn-lg btn-info disabled">
                            Approving...
                          </a>
                        )}

                        {status === "insufficient" && (
                          <a
                            className="btn btn-lg btn-success disabled"
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            Insufficient NFL
                          </a>
                        )}

                        {status === "open" && (
                          <a
                            className="btn btn-lg btn-success"
                            onClick={(event) => {
                              event.preventDefault();
                              this.openRareChest();
                            }}
                          >
                            Confirm open chest
                          </a>
                        )}
                      </p>
                      {this.state.tempHistory.length > 0 && (
                        <TempHistory tempHistory={this.state.tempHistory} />
                      )}
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between small"></div>
                </div>

                {/* <RareChest wallet={wallet} cardList={this.handleCardList} /> */}
              </div>
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card bg-img-1 h-100">
                  <div className="card-header d-flex justify-content-between"></div>
                  <div className="card-body d-flex flex-column">
                    <div className="card-text  text-center">
                      <img
                        src={chest2}
                        className={
                          "chest-img " + (activeChest === "epic" ? "hide" : "")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          activeChest !== "epic"
                            ? this.setState({ activeChest: "epic" })
                            : this.setState({ activeChest: "" });
                        }}
                      />
                    </div>
                    <p className="card-text text-center fw-bold text-3 text-sr">
                      EPIC CHEST
                    </p>

                    <p className="card-text d-flex justify-content-between fw-bold text-1">
                      <span>25 NFL + 100 LUCKY</span>
                      <span>
                        10 cards (C, SR, <span className="text-sr">SR</span>)
                      </span>
                    </p>
                    <p
                      className={
                        "card-text d-grid " +
                        (activeChest === "epic" ? "d-none" : "")
                      }
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ activeChest: "epic" });
                        }}
                        class="btn btn-lg  btn-primary"
                      >
                        Open chest
                      </a>
                    </p>
                    <div
                      className={
                        "mt-auto " + (activeChest !== "epic" ? "d-none" : "")
                      }
                    >
                      <p className="card-text d-grid gap-2">
                        {epicStatus === "connect" && (
                          <a
                            className="btn btn-lg btn-info"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#ConnectWalletModal"
                          >
                            Connect wallet
                          </a>
                        )}
                        {epicStatus === "approveNFL" && (
                          <a
                            className="btn btn-lg btn-info"
                            onClick={(event) => {
                              event.preventDefault();
                              this.approve();
                            }}
                          >
                            Approve NFL
                          </a>
                        )}
                        {epicStatus === "approveLUCKY" && (
                          <a
                            className="btn btn-lg btn-info"
                            onClick={(event) => {
                              event.preventDefault();
                              this.approveLucky();
                            }}
                          >
                            Approve LUCKY
                          </a>
                        )}

                        {epicStatus === "approving" && (
                          <a className="btn btn-lg btn-info disabled">
                            Approving...
                          </a>
                        )}

                        {epicStatus === "insufficientNFL" && (
                          <a
                            className="btn btn-lg btn-success disabled"
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            Insufficient NFL
                          </a>
                        )}

                        {epicStatus === "insufficientLUCKY" && (
                          <a
                            className="btn btn-lg btn-success disabled"
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            Insufficient LUCKY
                          </a>
                        )}

                        {epicStatus === "open" && (
                          <a
                            className="btn btn-lg btn-success"
                            onClick={(event) => {
                              event.preventDefault();
                              this.openEpicChest();
                            }}
                          >
                            Confirm open chest
                          </a>
                        )}
                      </p>
                      {this.state.tempHistory.length > 0 && (
                        <TempHistory tempHistory={this.state.tempHistory} />
                      )}
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between small"></div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4 py-3">
                <div className="card bg-img-1 h-100">
                  <div className="card-header d-flex justify-content-between"></div>
                  <div className="card-body d-flex flex-column">
                    <div className="card-text  text-center">
                      <img
                        src={chest3}
                        className={
                          "chest-img " +
                          (activeChest === "legendary" ? "hide" : "")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          activeChest !== "legendary"
                            ? this.setState({ activeChest: "legendary" })
                            : this.setState({ activeChest: "" });
                        }}
                      />
                    </div>
                    <p className="card-text text-center fw-bold text-3 text-ssr">
                      LEGENDARY CHEST
                    </p>

                    <p className="card-text d-flex justify-content-between fw-bold text-1">
                      <span>25 NFL + 200 LUCKY</span>
                      <span>
                        10 cards (C, R, SR,{" "}
                        <span className="text-ssr">SSR</span>)
                      </span>
                    </p>
                    <p
                      className={
                        "card-text d-grid " +
                        (activeChest === "legendary" ? "d-none" : "")
                      }
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ activeChest: "legendary" });
                        }}
                        class="btn btn-lg  btn-primary"
                      >
                        Open chest
                      </a>
                    </p>
                    <div
                      className={
                        "mt-auto " +
                        (activeChest !== "legendary" ? "d-none" : "")
                      }
                    >
                      <p className="card-text d-grid gap-2">
                        {legendaryStatus === "connect" && (
                          <a
                            className="btn btn-lg btn-info"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#ConnectWalletModal"
                          >
                            Connect wallet
                          </a>
                        )}
                        {legendaryStatus === "approveNFL" && (
                          <a
                            className="btn btn-lg btn-info"
                            onClick={(event) => {
                              event.preventDefault();
                              this.approve();
                            }}
                          >
                            Approve NFL
                          </a>
                        )}
                        {legendaryStatus === "approveLUCKY" && (
                          <a
                            className="btn btn-lg btn-info"
                            onClick={(event) => {
                              event.preventDefault();
                              this.approveLucky();
                            }}
                          >
                            Approve LUCKY
                          </a>
                        )}

                        {legendaryStatus === "approving" && (
                          <a className="btn btn-lg btn-info disabled">
                            Approving...
                          </a>
                        )}

                        {legendaryStatus === "insufficientNFL" && (
                          <a
                            className="btn btn-lg btn-success disabled"
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            Insufficient NFL
                          </a>
                        )}

                        {legendaryStatus === "insufficientLUCKY" && (
                          <a
                            className="btn btn-lg btn-success disabled"
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            Insufficient LUCKY
                          </a>
                        )}

                        {legendaryStatus === "open" && (
                          <a
                            className="btn btn-lg btn-success"
                            onClick={(event) => {
                              event.preventDefault();
                              this.openLegendaryChest();
                            }}
                          >
                            Confirm open chest
                          </a>
                        )}
                      </p>
                      {this.state.tempHistory.length > 0 && (
                        <TempHistory tempHistory={this.state.tempHistory} />
                      )}
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between small"></div>
                </div>
              </div>
              {/* <a
                className="btn btn-lg btn-soft-brand"
                onClick={(event) => {
                  event.preventDefault();
                  this.approve();
                }}
              >
                Approve NFL ({this.state.allowedAmount}){" "}
                {this.state.approveStatus}
              </a>

              <a
                className="btn btn-lg btn-soft-brand"
                onClick={(event) => {
                  event.preventDefault();
                  this.approveLucky();
                }}
              >
                Approve LUCKY ({this.state.allowedLuckyAmount}){" "}
                {this.state.approveLuckyStatus} {LUCKY_REQUIRE["epic"]}
              </a> */}

              {/* <a
                className="btn btn-lg btn-soft-brand"
                onClick={(event) => {
                  event.preventDefault();
                  this.getCardID(9611742976);
                }}
              >
                getCardID
              </a>
              <a
                className="btn btn-lg btn-soft-brand"
                onClick={(event) => {
                  event.preventDefault();
                  this.getUri();
                }}
              >
                getUri
              </a>
              <a
                className="btn btn-lg btn-soft-brand"
                data-bs-toggle="modal"
                data-bs-target="#ResultOpenChestModal"
              >
                getUri
              </a>*/}
              {/* <a
                className="btn btn-lg btn-soft-brand"
                onClick={(event) => {
                  event.preventDefault();
                  this.showModal();
                }}
              >
                getCardID {activeChest}
              </a> */}
            </div>{" "}
            <div className="gap-6" />
          </div>
        </main>
        <ResultOpenChestModal cardList={this.state.cardList} />
      </>
    );
  }
}

export default withRouter(OpenChest);
