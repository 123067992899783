import React, { Component } from "react";
import logo from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";

export default class SideBar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="sidebar hide bg-img-1">
        <div className="sidebar-container mt-3">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                className="nav-link"
                exact
                activeClassName="active"
              >
                CardFights Gallery
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/open-chest"
                className="nav-link"
                exact
                activeClassName="active"
              >
                Open Treasure Chest
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/NFT-market"
                className="nav-link"
                exact
                activeClassName="active"
              >
                NFT Marketplace
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/farming"
                className="nav-link"
                exact
                activeClassName="active"
              >
                Farming
              </NavLink>
            </li>

            <hr />

            <li>
              <NavLink
                to="/my-cards"
                className="nav-link"
                exact
                activeClassName="active"
              >
                My cards
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
