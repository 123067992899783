//* contractAddress
// import RINKEBY from "../../abis/contract_rinkeby.json";
// import BUYCHEST from "../../abis/BuyPackage.json";
import LUCKYCHEST from "../../abis/BuyPackageLucky.json";

import NFTCONTRACT from "../../abis/NftLegendCard.json";
import FARMLP from "../../abis/NFTLegendChef.json";

import { NETWORK, EXPLORER } from "../constants";

export const getContract = (contractName) => {
  let contract;
  let contractAddress;
  // if (contractName === "buyRareChest") {
  //   //mainnet
  //   contractAddress = BUYCHEST.networks[NETWORK].address;
  //   if (contractAddress) {
  //     contract = new window.web3.eth.Contract(BUYCHEST.abi, contractAddress);
  //   }
  // }
  if (contractName === "luckyChest") {
    //mainnet
    contractAddress = LUCKYCHEST.networks[NETWORK].address;
    if (contractAddress) {
      contract = new window.web3.eth.Contract(LUCKYCHEST.abi, contractAddress);
    }
  }
  if (contractName === "nftContract") {
    //mainnet
    contractAddress = NFTCONTRACT.networks[NETWORK].address;
    if (contractAddress) {
      contract = new window.web3.eth.Contract(NFTCONTRACT.abi, contractAddress);
    }
  }
  if (contractName === "farmLP") {
    //mainnet
    contractAddress = FARMLP.networks[NETWORK].address;
    if (contractAddress) {
      contract = new window.web3.eth.Contract(FARMLP.abi, contractAddress);
    }
  }

  console.group("%c" + contractName, "color: green;");
  console.log("contract:", contract);
  console.log("address:", contractAddress);
  console.log("explorer:", EXPLORER + "address/" + contractAddress);
  console.groupEnd();
  return { contractAddress, contract };
};
