import React, { Component } from "react";

export default class Card3D extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={`card-fight ` + this.props.className}>
        <div className="content">
          <img className="front" src={this.props.front} />
          <img className="back" src={this.props.back} />
        </div>
      </div>
    );
  }
}
