import axios from "axios";
import { PAIR_ID, TOKEN_INFO } from "../constants";
import abi from "human-standard-token-abi";

//convert address to short address
export const shortAddress = (address) => {
  if (address === null || address === "") {
    return "";
  }
  return (
    address.substring(0, 5) +
    "..." +
    address.substring(address.length - 4, address.length)
  );
};

export const safeAmount = (string, decimal, significant) => {
  //* cut string to 6
  significant = significant || 6;
  //* cut string to
  if (significant === -1) {
    significant = decimal - 1;
  }
  if (string === null) return 0;
  if (string.length <= decimal - significant) return 0;
  string = string.slice(0, string.length - decimal + significant);
  return parseInt(string) / 10 ** significant;
};

//load Uniswap price
export const loadUniSwapPrice = async (pair) => {
  try {
    const cash = await axios.post(
      "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",
      // const cash = await axios.post('https://graph.mdex.cc/subgraphs/name/mdex/swap',
      {
        query:
          `{
                pair(id: "` +
          PAIR_ID[pair] +
          `"){
                    token0 {
                    id
                    symbol
                    }
                    token1 {
                    id
                    symbol
                    }
                    token1Price
                    token0Price
                    
                } 
            }`,
      }
    );
    // console.log('price', cash.data.data)
    return parseFloat(cash.data.data.pair.token1Price);
  } catch {
    console.log(Error);
  }
};

//load Uniswap price
export const loadUniSwapPrice0 = async (pair) => {
  try {
    const cash = await axios.post(
      "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",
      // const cash = await axios.post('https://graph.mdex.cc/subgraphs/name/mdex/swap',
      {
        query:
          `{
              pair(id: "` +
          PAIR_ID[pair] +
          `"){
                  token0 {
                  id
                  symbol
                  }
                  token1 {
                  id
                  symbol
                  }
                  token1Price
                  token0Price
                  
              } 
          }`,
      }
    );
    // console.log('price', cash)
    return parseFloat(cash.data.data.pair.token0Price);
  } catch {
    console.log(Error);
  }
};

//* Load balance
export const loadAccountBalance = async (token, account) => {
  if (account === null || account === "") return "wrong address";
  let contract = new window.web3.eth.Contract(abi, TOKEN_INFO[token].address);
  let balance = await contract.methods.balanceOf(account).call();
  balance = safeAmount(balance, TOKEN_INFO[token].decimal);
  // balance = parseFloat(balance) / 10 ** TOKEN_INFO[token].decimal
  // console.log(account, token, balance)
  return balance;
};

export const loadPriceUnipool = async (pair, token1, token2) => {
  let token1Balance = await loadAccountBalance(token1, PAIR_ID[pair]);
  let token2Balance = await loadAccountBalance(token2, PAIR_ID[pair]);

  console.log("token1Balance", token1, token1Balance);
  console.log("token2Balance", token2, token2Balance);
  console.log("Price", token1, token2Balance / token1Balance);

  return token2Balance / token1Balance;
};

export const myIP = async () => {
  try {
    const response = await axios.get("http://ip-api.com/json");
    console.log(response);
    return `Your IP ${response.data.query} (${response.data.countryCode} - ${response.data.city})`;
  } catch (error) {
    console.error(error);
  }
};

export const blockTimestamp = async (block) => {
  try {
    const response = await window.web3.eth.getBlock(block);
    // console.log(response.timestamp);
    return response.timestamp;
  } catch (error) {
    console.error(error);
  }
};

export const cardObj = (i) => {
  let kind = i < 49 ? "c" : i < 83 ? "r" : i < 103 ? "sr" : "ssr";
  let limit = i < 49 ? 100 : i < 83 ? 50 : i < 103 ? 25 : 10;

  return {
    cardID: i,
    kind,
    limit,
  };
};

// Convert 10e21 to '10000000000000000000'
export const convert = (n) => {
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
};
