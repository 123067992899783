import React from "react";
import { getContract } from "../utils/ContractLoader";
import {
  TOKEN_INFO,
  TOTAL_APPROVE,
  CONFIRM_NUMBER,
  EXPLORER,
} from "../constants";
import $ from "jquery";
import { shortAddress, loadAccountBalance, safeAmount } from "../utils/";
import arrow from "../../assets/images/svg/arrow-down-1.svg";
import lock from "../../assets/images/svg/lock.svg";
import History from "./History";
import TempHistory from "./TempHistory";

import { Logos } from "../../assets/CoinLogos";
export default class AppCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null, //* CONTRACT
      contractAddress: null, //* CONTRACT ADDRESS

      // buyTokenAddress: null,
      // buyTokenDecimal: null,
      approveStatus: false,
      tab: "buy",

      aurClaimable: 0,

      //* Balance
      balanceUSDT: null,
      balanceAUR: null,

      //* Buy Amount
      amountUSDT: "",
      amountAUR: "",

      showSponsor: false,
      sponsor: "",
      specifiedSponsor: false,
      invalidSponsor: false,
      tokenPrice: 1,
      allowedAmount: 0,

      //* History
      tempHistory: [],
      historyArray: [],
      errMess: "",

      broadcasting: false,

      claimAvailable: false,
    };

    this.handleUsdtChange = this.handleUsdtChange.bind(this);
    this.handleAurChange = this.handleAurChange.bind(this);
    this.handleSponsorChange = this.handleSponsorChange.bind(this);
  }

  async componentWillMount() {
    await this.setState(getContract());
    this.getUserInfo();

    this.getPrice();
    this.checkClaimStatus();
    await this.setState({
      buyTokenAddress: TOKEN_INFO["USDT"].address,
      buyTokenDecimal: TOKEN_INFO["USDT"].decimal,
    });
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.wallet !== this.props.wallet) {
      if (nextProps.wallet.status === "connected") {
        this.getUserInfo();
      } else {
        this.setState({
          balanceUSDT: 0,
          balanceAUR: 0,
        });
      }
    }
  }

  async componentDidMount() {
    // this.loadWeb3();
    // GET TOKEN ADDRESS
  }

  handleUsdtChange = (e) => {
    let amountUSDT = Math.trunc(e.target.value * 10 ** 6) / 10 ** 6;
    this.setState({
      amountUSDT,
      amountAUR:
        amountUSDT > 0
          ? (amountUSDT * 10 ** 6) / this.state.tokenPrice / 10 ** 6
          : 0,
    });
  };

  handleAurChange = (e) => {
    this.setState({
      amountAUR: e.target.value,
      amountUSDT:
        e.target.value > 0
          ? (e.target.value * 10 ** 6 * this.state.tokenPrice) / 10 ** 6
          : 0,
    });
  };

  handleSponsorChange = (e) => {
    window.web3.utils.isAddress(e.target.value) || !e.target.value
      ? this.setState({
          sponsor: e.target.value,
          invalidSponsor: false,
        })
      : this.setState({
          sponsor: e.target.value,
          invalidSponsor: true,
        });
  };

  checkSponsor() {
    let sponsor = this.state.sponsor;
    const web3 = window.web3;
    return web3.utils.isAddress(sponsor);
  }
  //* GET USER INFO ON CONTRACT
  //* such as: balance, allowed amount
  async getUserInfo() {
    var account = this.props.wallet.account;
    var contractAddress = this.state.contractAddress;
    var contract = this.state.contract;

    if (account) {
      //* GET TOKENS BALANCE
      let [balanceUSDT, balanceAUR] = await Promise.all([
        loadAccountBalance("USDT", account),
        loadAccountBalance("AUR", account),
      ]);

      //* GET ALLOWED BALANCE
      const web3 = window.web3;
      var abi = require("human-standard-token-abi");
      let contractUSDT = new web3.eth.Contract(abi, TOKEN_INFO["USDT"].address);
      // console.log(contract, contractAddress);
      let allowedAmount = await contractUSDT.methods
        .allowance(account, contractAddress)
        .call();

      allowedAmount =
        parseInt(allowedAmount) / 10 ** TOKEN_INFO["USDT"].decimal;

      let sponsor = await contract.methods.mappingSponsor(account).call();
      //* IF SPONSOR === 0x00 => user can edit
      sponsor =
        sponsor === "0x0000000000000000000000000000000000000000" ? "" : sponsor;
      let specifiedSponsor = sponsor !== "";

      let aurClaimable = await contract.methods.totalAur(account).call();
      aurClaimable = safeAmount(aurClaimable, TOKEN_INFO["AUR"].decimal);
      // console.log("aurClaimable", aurClaimable);

      //* GET BUY HISTORY

      this.getBuyHistory();

      this.setState({
        balanceUSDT,
        balanceAUR,
        allowedAmount,
        sponsor,
        specifiedSponsor,
        aurClaimable,
      });
    }
  }

  // async getBuyHistory() {
  //   var contract = this.state.contract;
  //   var account = this.props.wallet.account;

  //   let historyArray = [];
  //   contract.events.HistoryBuyToken(
  //     {
  //       filter: { buyer: "0x76dB1529F610C95E4C293561AA16aBEF632764fd" },
  //       fromBlock: 0,
  //     },
  //     function (error, event) {
  //       historyArray = event;
  //       console.log("historyArray", historyArray);
  //     }
  //   );
  // }

  async getBuyHistory() {
    var contract = this.state.contract;
    var account = this.props.wallet.account;

    let historyArray = await contract
      .getPastEvents("historyBuyToken", {
        filter: { buyer: account },
        fromBlock: 0,
        toBlock: "latest",
      })
      .then(function (events) {
        //process array here
        if (events.length > 0) {
          events = events.filter((i) => i.returnValues.buyer === account);
          // console.log(events);
          return events.map(function (i) {
            let elem = {
              tx: i.transactionHash,
              aur: i.returnValues.aurbuy,
              usdt: i.returnValues.usdtbuy,
              timestamp: i.returnValues.timebuy,
            };

            return elem;
          });
        } else return [];
      });

    this.setState({ historyArray });
    // console.log("historyArray", this.state.historyArray);
  }

  async getPrice() {
    let contract = this.state.contract;
    let account = this.props.wallet.account;

    let tokenPrice = await contract.methods.price().call();
    tokenPrice = parseInt(tokenPrice) / 10 ** 6;
    this.setState({ tokenPrice });
    // console.log(tokenPrice);
  }

  async checkClaimStatus() {
    let contract = this.state.contract;
    let account = this.props.wallet.account;

    let claimAvailable = await contract.methods.openClaim().call();
    this.setState({ claimAvailable });
    // console.log(tokenPrice);
  }

  // async mappingSponsor() {
  //   let contract = this.state.contract;
  //   let account = this.props.wallet.account;
  //   let result = await contract.methods.mappingSponsor(account).call();
  //   console.log(result);
  // }

  async approve() {
    const web3 = window.web3;
    let abi = require("human-standard-token-abi");
    let stakeTokenContract = new web3.eth.Contract(
      abi,
      this.state.buyTokenAddress
    );
    // console.log("buyTokenAddress", this.state.buyTokenAddress);

    let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
    this.setState({ approveStatus: false });

    stakeTokenContract.methods
      .approve(this.state.contractAddress, totalApprove)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // console.log(hash);
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Approve USDT",
        });
        // console.log(this.state.tempHistory);
        this.setState({ approveStatus: "pending" });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          // console.log(receipt.transactionHash);
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          // console.log(this.state.tempHistory);

          this.setState({ approveStatus: "success" });
        }
      })
      .on("error", (error) => {
        this.setState({
          approveStatus: "error",
          errMess: error.message,
        });
      });
  }

  buyAur() {
    const web3 = window.web3;
    let sponsor =
      this.state.sponsor || "0x0000000000000000000000000000000000000000";
    // console.log("amount", this.state.amountUSDT);
    let amountDecimal = this.convert(
      this.state.amountUSDT * 10 ** TOKEN_INFO["USDT"].decimal
    ).toString();
    // console.log("amountDecimal", amountDecimal);
    // console.log("sponsor", sponsor);
    this.setState({ broadcasting: true });
    this.state.contract.methods
      .buyTokenAur(sponsor, amountDecimal)
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // $("#loadingModal").modal("show");
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Buy " + this.state.amountAUR + " AUR",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
          broadcasting: false,
        });
      });
  }

  claim() {
    const web3 = window.web3;
    this.state.contract.methods
      .claimToken()
      .send({
        from: this.props.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        // $("#loadingModal").modal("show");
        this.state.tempHistory.push({
          hash,
          status: "pending",
          type: "Claim AUR",
        });
        this.setState({ broadcasting: false });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          let index = this.state.tempHistory.findIndex(
            (i) => i.hash === receipt.transactionHash
          );
          this.state.tempHistory[index].status = "success";

          this.getUserInfo();
        }
      })
      .on("error", (error) => {
        this.setState({
          errMess: error.message,
        });
      });
  }

  convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }
  clearMess() {
    this.setState({ errMess: "" });
  }

  render() {
    let wallet = this.props.wallet;
    let status = this.state.broadcasting
      ? "broadcasting"
      : this.state.approveStatus === "pending"
      ? "approving"
      : !this.state.amountUSDT
      ? "enter"
      : this.state.amountUSDT > this.state.allowedAmount
      ? "approve"
      : this.state.amountUSDT <= 0
      ? "invalid"
      : "buy";
    return (
      <>
        <div className="tab mb-4 mx-auto">
          <div
            className={"tab-item " + (this.state.tab === "buy" ? "active" : "")}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ tab: "buy" });
            }}
          >
            Buy AUR
          </div>
          <div
            className={
              "tab-item " + (this.state.tab === "claim" ? "active" : "")
            }
            onClick={(e) => {
              e.preventDefault();
              this.setState({ tab: "claim" });
            }}
          >
            Claim
          </div>
          <div
            className={
              "tab-item " + (this.state.tab === "history" ? "active" : "")
            }
            onClick={(e) => {
              e.preventDefault();
              this.setState({ tab: "history" });
            }}
          >
            Buy history
          </div>
        </div>
        {this.state.tab === "buy" && (
          <>
            <div className="card bg-img-1">
              <div className="card-body text-center">
                <div className="card-title fs-5 fw-bold">Buy AUR</div>

                <div className="exchange-form border-item">
                  <div className="input-info">
                    <div className="input-token ">
                      <img className="img-inline me-1" src={Logos["USDT"]} />
                      USDT
                    </div>
                    <input
                      className="input-detail monospace"
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      type="number"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0"
                      minLength="1"
                      maxLength="79"
                      spellCheck="false"
                      value={this.state.amountUSDT}
                      onChange={this.handleUsdtChange}
                    />
                  </div>

                  <div className="balance text-start text-secondary">
                    Balance{" "}
                    {this.state.balanceUSDT ? this.state.balanceUSDT : "0"} USDT{" "}
                    {this.state.balanceUSDT !== this.state.amountUSDT && (
                      <a
                        className="pointer text-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            amountUSDT: this.state.balanceUSDT,
                            amountAUR:
                              this.state.balanceUSDT / this.state.tokenPrice,
                          });
                        }}
                      >
                        (Max)
                      </a>
                    )}
                  </div>
                </div>

                <div className="middle-info">
                  <span className="rate-info monospace">
                    <svg
                      className="me-1 text-secondary"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8V13"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4 6C2.75 7.67 2 9.75 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C10.57 2 9.2 2.3 7.97 2.85"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.9941 16H12.0031"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    1 AUR = {this.state.tokenPrice} USDT
                  </span>
                  <span className="arrow-down">
                    <img src={arrow} />
                  </span>
                </div>

                <div className="exchange-form border-item">
                  <div className="input-info">
                    <div className="input-token">
                      {" "}
                      <img className="img-inline me-1" src={Logos["AUR"]} /> AUR
                    </div>
                    <input
                      className="input-detail monospace"
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      type="number"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0"
                      minLength="1"
                      maxLength="79"
                      spellCheck="false"
                      value={this.state.amountAUR}
                      onChange={this.handleAurChange}
                    />
                  </div>

                  <div className="balance text-start text-secondary">
                    Balance{" "}
                    {this.state.balanceAUR ? this.state.balanceAUR : "0"} AUR
                  </div>
                </div>

                <div className="s-box border-item my-4">
                  <div
                    className="s-form pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showSponsor: !this.state.showSponsor });
                    }}
                  >
                    <div className="text-secondary">
                      Sponsor address (optional)
                    </div>
                    <div className="text-secondary">
                      {this.state.showSponsor ? "Hide ↑" : "Show ↓"}
                    </div>
                  </div>
                  {this.state.showSponsor ? (
                    <>
                      <input
                        className="input-s monospace"
                        autoComplete="off"
                        autoCorrect="off"
                        type="text"
                        placeholder="0xAa9e322bf2753CEF80AC6F8DbeEEbFD45E769B62"
                        spellCheck="false"
                        value={this.state.sponsor}
                        onChange={this.handleSponsorChange}
                        disabled={this.state.specifiedSponsor}
                      />
                      {this.state.invalidSponsor && (
                        <div className="balance text-start text-danger mt-3">
                          Invalid address!
                        </div>
                      )}
                      {this.state.specifiedSponsor && (
                        <div className="balance text-start text-brand mt-3">
                          You has specified sponsor. You can not change anymore!
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-3 d-grid">
                  {status === "broadcasting" && (
                    <a className="btn btn-lg btn-soft-brand disabled">
                      Sending...
                    </a>
                  )}

                  {status === "approve" && (
                    <a
                      className="btn btn-lg btn-soft-brand"
                      onClick={(event) => {
                        event.preventDefault();
                        this.approve();
                      }}
                    >
                      Approve USDT
                    </a>
                  )}

                  {status === "approving" && (
                    <a className="btn btn-lg btn-soft-brand disabled">
                      Approving...
                    </a>
                  )}
                  {/* INFO BUTTON */}
                  {status === "enter" && (
                    <a className="btn btn-lg btn-soft-secondary">
                      Enter amount
                    </a>
                  )}
                  {status === "invalid" && (
                    <a className="btn btn-lg btn-soft-secondary">
                      Invalid amount
                    </a>
                  )}
                  {status === "buy" && (
                    <a
                      className="btn btn-lg btn-soft-brand"
                      onClick={(event) => {
                        event.preventDefault();
                        this.buyAur();
                      }}
                    >
                      Buy
                    </a>
                  )}
                </div>
              </div>
            </div>

            {this.state.errMess && (
              <div className="card text-danger mt-3">
                <div className="card-body">
                  <div className="card-text d-flex justify-content-between">
                    <span>
                      <strong>Error:</strong> {this.state.errMess}
                    </span>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={(event) => {
                        event.preventDefault();
                        this.clearMess();
                      }}
                    ></button>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="card mt-5">
              <div className="card-body text-center">
                <div className="card-text text-secondary">Debug</div>
                <div className="card-text">
                  Allow amount {this.state.allowedAmount}
                </div>
                <div className="card-text">USDT {this.state.amountUSDT}</div>
                <div className="card-text">AUR {this.state.amountAUR}</div>
                <div className="card-text">Status {status}</div>
                <div className="card-text">
                  specifiedSponsor: {this.state.specifiedSponsor}
                </div>

                <div className="card-text monospace">
                  tempHistory
                  {this.state.tempHistory.length}
                </div>
                <div className="card-text gap-3">
                  <a
                    className="btn btn-soft-brand me-1"
                    onClick={(event) => {
                      event.preventDefault();
                      this.approve();
                    }}
                  >
                    Approve USDT
                  </a>
                  <a
                    className="btn btn-soft-brand"
                    onClick={(event) => {
                      event.preventDefault();
                      this.mappingSponsor();
                    }}
                  >
                    MappingSponsor
                  </a>
                </div>

                <div className="card-text">sponsor: {this.state.sponsor}</div>

                <div className="card-text gap-3">
                  <a
                    className="btn btn-soft-brand me-1"
                    onClick={(event) => {
                      event.preventDefault();
                      this.getBuyHistory();
                    }}
                  >
                    Get history
                  </a>
                </div>

                <div className="card-text gap-3">
                  <a
                    className="btn btn-soft-brand me-1"
                    onClick={(event) => {
                      event.preventDefault();
                      this.checkClaimStatus();
                    }}
                  >
                    claimStatus: {this.state.claimAvailable ? "true" : "false"}
                  </a>
                </div>
              </div>
            </div> */}
          </>
        )}
        {this.state.tab === "claim" && (
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title fs-5 fw-bold">Claim AUR</div>
              <div className="card-text text-secondary mt-4">Balance AUR</div>
              <div className="card-text fs-2 monospace my-2 border-item">
                {this.state.aurClaimable ? (
                  this.state.aurClaimable
                ) : (
                  <span className="text-secondary">0.0</span>
                )}
              </div>
              <div className="card-text text-secondary mb-4">
                Claim status:{" "}
                {this.state.claimAvailable ? (
                  <span className="text-brand">Open</span>
                ) : (
                  <span className="text-danger">Close</span>
                )}
              </div>

              <div className="mt-3 d-grid">
                <button
                  className="btn btn-lg btn-soft-brand"
                  disabled={
                    !this.state.claimAvailable || this.state.aurClaimable === 0
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    this.claim();
                  }}
                >
                  Claim
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.tab === "history" && (
          <History
            wallet={this.props.wallet}
            historyData={this.state.historyArray}
            locked="true"
          />
        )}

        {this.state.tempHistory.length > 0 && (
          <TempHistory tempHistory={this.state.tempHistory} />
        )}
      </>
    );
  }
}
