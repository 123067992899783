import React from "react";
import logo from "../../assets/images/logo.svg";

export default class ConnectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let wallet = this.props.wallet;
    return (
      <div className="card mt-5 py-5 bg-img-1">
        <div className="card-body text-center">
          <div className="card-text py-2 text-secondary">
            <img src={logo} alt="" width="150" />
          </div>
          <div className="gap-3" />

          <div className="card-text py-2 text-secondary">
            Please connect your wallet
          </div>
          <div className="card-text d-grid mt-3 px-2">
            <a
              className="btn btn-lg btn-primary text-white"
              data-bs-toggle="modal"
              data-bs-target="#ConnectWalletModal"
              href="#"
            >
              Connect Wallet
            </a>
          </div>
        </div>
      </div>
    );
  }
}
