import React from "react";
import { NETWORK, RPC } from "./components/constants";
import Header from "./components/Header";
import "./assets/style/font.css";
import "./assets/style/custom.scss";
import "./assets/style/utils.scss";
import { useWallet, UseWalletProvider } from "use-wallet";

function App() {
  const wallet = useWallet();
  return (
    <div className="App d-flex flex-column">
      <Header wallet={wallet} />
    </div>
  );
}

export default () => (
  <UseWalletProvider
    chainId={NETWORK}
    connectors={{
      walletconnect: { rpcUrl: RPC },
    }}
  >
    <App />
  </UseWalletProvider>
);
